<template>
  <div>
    <ZebraboxDataTable
      apipath="countries"
      :headers="[
        {
          text: 'Country Code',
          value: 'countrycode',
        },
        {
          text: 'Name',
          value: `name.${$store.state.languages[0]}`,
        },
        {
          text: 'Icon Code',
          value: 'iconcode',
        },
        {
          text: 'Prefix',
          value: 'prefix',
        },
        {
          text: 'Position',
          value: 'position',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-text-field label="Country Code" v-model="item.countrycode" />
        <lang-tabs :model="item" :config="{
              name: {
                label: 'Name',
              },
            }" />
        <v-text-field label="Icon Code" v-model="item.iconcode" />
        <v-text-field label="Prefix" v-model="item.prefix" />
        <v-text-field label="Position" v-model="item.position" />

      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Countries',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      locations: [],
      storagesizes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
    newItem(action) {
      action({
        countrycode: '',
        iconcode: '',
        prefix: '',
        position: 0,
        name: {
          de: '',
          fr: '',
          en: '',
        },
      });
    },
    editItem(item, action) {
      BackendService.updateCountry(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this country?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteCountry(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
  },
};
</script>
