<template>
    <div>
      <v-container>
        <v-sheet elevation="1">
          <v-toolbar dense elevation="0">
            <h2 v-if="title">{{title}}</h2>
            <v-spacer></v-spacer>

            <v-menu offset-y
                    :close-on-content-click="false" v-if="filters.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="filter.length"
                  :value="filter.length"
                  overlap
                >
                  <v-btn elevation="1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>fal fa-filter</v-icon>&nbsp;
                    Filter
                  </v-btn>
                </v-badge>
              </template>

              <v-list
                flat
                subheader
              >
                <v-list-item-group
                  v-model="filter"
                  multiple
                  active-class=""
                >
                  <v-list-item v-for="(item, id) in tablefilters" v-bind:key="id">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-menu>
          </v-toolbar>
          <v-container v-if="filter.length > 0">
            <v-row>
              <v-col v-for="(item, id) in filter" v-bind:key="id" cols="4">
                {{ tablefilters[item].title }}
                <div v-if="tablefilters[item].type === 'daterange'">
                  <v-menu
                    v-model="tablefilters[item].from"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tablefilters[item].fromDateFormatted"
                        label="From"
                        prepend-icon="fal fa-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @blur="tablefilters[item].fromDate =
                        parseDate(tablefilters[item].fromDateFormatted)"
                        @click:clear="clearFromDate(item)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tablefilters[item].fromDate"
                      @input="onFromDatePickerInput(item)"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="tablefilters[item].to"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="tablefilters[item].toDateFormatted"
                        label="To"
                        prepend-icon="fal fa-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        @blur="tablefilters[item].toDate =
                        parseDate(tablefilters[item].toDateFormatted)"
                        @click:clear="clearToDate(item)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="tablefilters[item].toDate"
                      @input="onToDatePickerInput(item)"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div v-else-if="tablefilters[item].type === 'relation'">
                  <v-combobox
                    v-model="tablefilters[item].value"
                    :items="tablefilters[item].data"
                    clearable
                    multiple
                    small-chips
                    item-text="name"
                    item-value="id"
                    @change="getDataFromApi"
                  ></v-combobox>
                </div>
                <div v-else-if="tablefilters[item].type === 'text'">
                  <v-text-field
                    v-model="tablefilters[item].value"
                    clearable
                    @change="getDataFromApi"
                    ></v-text-field>
                </div>
                <div v-else-if="tablefilters[item].type === 'bool'">
                  <v-checkbox
                    v-model="tablefilters[item].value"
                    @change="getDataFromApi"
                  ></v-checkbox>
                </div>
                <div v-else>
                  @todo!
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-container>
      <v-container>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          class="elevation-1"
          :footer-props="{'items-per-page-options':[10, 50, 100, 200]}"
          :items-per-page="50"
          :item-class="item_class"
        >
          <template v-for="header in getSlots(headers)"
                    v-slot:[header.slotName]="{ item }"
                    >
            <div v-bind:key="header.value">
              <div v-if="header.type === 'yesno'">
                <v-chip color="#5cb85c" text-color="white" v-if="item[header.value] == 1">
                  Ja</v-chip>
                <v-chip color="#dd4b39" text-color="white" v-else>
                  Nein</v-chip>
              </div>
              <div v-if="header.type === 'categorysizes'">
                {{ item[header.value].map((item) => item.sizeName ).join(', ') }}
              </div>
              <div v-if="header.type === 'mapnames'">
                {{ item[header.value].map((item) => item.name).join(', ') }}
              </div>
              <div v-if="header.type === 'date'">
                {{ asDate(item[header.value]) }}
              </div>
              <div v-if="header.type === 'datetime'">
                {{ asDateTime(item[header.value]) }}
              </div>
              <div v-if="header.type === 'insurancepremiums'">
                {{ item[header.value].map((item) => `max:${item.maxinsuredvalue}`).join(', ') }}
              </div>
              <div v-if="header.type === 'matrixranges'">
                {{ item[header.value].map((item) => `${item.fromUnit}-${item.toUnit}`).join(', ') }}
              </div>
            </div>
          </template>
          <template v-slot:top v-if="hasNewItemListener">
            <v-toolbar
              flat
            >
              <v-spacer />
              <v-btn color="primary" class="mb-2" @click="newItem">
                New entry
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-no-wrap">
              <v-icon
                v-if="hasEditItemListener"
                small
                class="mr-2"
                @click="editItem(item)"
              >
                far fa-edit
              </v-icon>
              <v-icon
                v-if="hasDeleteItemListener"
                small
                class="mr-2"
                @click="deleteItem(item)"
              >
                far fa-trash
              </v-icon>
              <v-icon
                v-if="hasSpecialActionListener"
                class="ml-2"
                small
                @click="specialAction(item)"
              >
                far fa-check-square
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-container>
      <v-cntainer v-if="hasExportListener">
        <v-sheet elevation="1" class="pa-2 pr-4 text-right">
          <v-btn @click.prevent="$emit('exportAction', getActiveFilters())">Export</v-btn>
        </v-sheet>
      </v-cntainer>
      <v-dialog
        v-model="editDialog"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <slot name="editForm" v-bind:item="editedItem"></slot>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeEdit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="saving"
              @click="saveEdit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import BackendService from '../service/BackendService';

export default {
  name: 'ZebraboxDataTable',
  props: {
    apipath: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    item_class: {
      type: Function,
      default: () => (() => ''),
    },
    specialActionIcon: {
      type: String,
      default: '',
    },
    conditionalStyle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filter: [],
      tablefilters: [],
      options: {},
      loading: false,
      items: [
      ],
      totalItems: 0,
      editedIndex: -1,
      editedItem: { },
      editDialog: false,
      saving: false,
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.filters.forEach((item) => {
      const newItem = {
        title: item.title,
        type: item.type,
        field: item.field,
      };

      this.tablefilters.push(newItem);

      switch (item.type) {
        case 'relation':
          newItem.data = item.data;
          break;
        default:
          break;
      }
    });

    let filterNum = 0;
    for (let i = 0; i < this.tablefilters.length; i += 1) {
      this.filter.push(filterNum);
      filterNum += 1;
    }
  },
  computed: {
    hasExportListener() {
      return this.$listeners && this.$listeners.exportAction;
    },
    hasNewItemListener() {
      return this.$listeners && this.$listeners.newAction;
    },
    hasEditItemListener() {
      return this.$listeners && this.$listeners.editAction;
    },
    hasDeleteItemListener() {
      return this.$listeners && this.$listeners.deleteAction;
    },
    hasSpecialActionListener() {
      return this.$listeners && this.$listeners.specialAction;
    },
  },
  methods: {
    asDate(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return `${day}.${month + 1}.${year}`;
    },
    asDateTime(timestamp) {
      const date = new Date(timestamp * 1000);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hour = date.getHours().toString().padStart(2, '0');
      const min = date.getMinutes().toString().padStart(2, '0');
      const sec = date.getSeconds().toString().padStart(2, '0');
      return `${day}.${month + 1}.${year} ${hour}:${min}:${sec}`;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.editDialog = true;
    },
    saveEdit() {
      this.saving = true;
      this.$emit('editAction', this.editedItem, this.saveEditDone);
    },
    saveEditDone() {
      this.saving = false;
      this.editDialog = false;
      this.getDataFromApi();
    },
    closeEdit() {
      this.editDialog = false;
    },
    newItem() {
      this.$emit('newAction', this.newItemDone);
    },
    deleteItem(item) {
      this.$emit('deleteAction', item, this.deleteItemDone);
    },
    specialAction(item) {
      this.$emit('specialAction', item, this.getDataFromApi);
    },
    deleteItemDone() {
      this.getDataFromApi();
    },
    newItemDone(object) {
      this.editedItem = object;
      this.editDialog = true;
    },
    getSlots(headers) {
      const retVal = [];

      headers.forEach((item) => {
        if (item.type !== undefined) {
          const newItem = { ...item };
          newItem.slotName = `item.${newItem.value}`;
          retVal.push(newItem);
        }
      });

      return retVal;
    },
    getDataFromApi() {
      this.loading = true;

      const activeFilters = this.getActiveFilters();

      BackendService.getDataTableData(this.apipath, this.options, activeFilters)
        .then((data) => {
          this.items = data.data.items;
          this.totalItems = data.data.total;
          this.loading = false;
        });
    },
    getActiveFilters() {
      const activeFilters = [];
      this.filter.forEach((item) => {
        activeFilters.push(this.tablefilters[item]);
      });
      return activeFilters;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    onFromDatePickerInput(item) {
      this.tablefilters[item].from = false;
      this.tablefilters[item].fromDateFormatted = this.formatDate(this.tablefilters[item].fromDate);
      this.getDataFromApi();
    },
    clearFromDate(item) {
      this.tablefilters[item].fromDate = null;
      this.getDataFromApi();
    },
    onToDatePickerInput(item) {
      this.tablefilters[item].to = false;
      this.tablefilters[item].toDateFormatted = this.formatDate(this.tablefilters[item].toDate);
      this.getDataFromApi();
    },
    clearToDate(item) {
      this.tablefilters[item].toDate = null;
      this.getDataFromApi();
    },
  },
};
</script>

<style scoped>

</style>
