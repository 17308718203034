<template>
  <div>
    <ZebraboxDataTable
      apipath="translations"
      :headers="headers"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:editForm="{ item }">
        <v-text-field label="Key" v-model="item.key" />
        <v-textarea
          :label="language.charAt(0).toUpperCase() + language.slice(1)"
          v-model="item[language]"
          auto-grow rows="1"
          v-for="language in $store.state.languages"
          v-bind:key="language"
        />
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';

export default {
  name: 'Translations',
  components: {
    ZebraboxDataTable,
  },
  data() {
    return {
      filters: [
        {
          title: 'Key',
          type: 'text',
          field: 'key',
        },
        {
          title: 'Content',
          type: 'text',
          field: 'content',
        },
        {
          title: 'Domain',
          type: 'relation',
          field: 'domain',
          data: [],
        },
        {
          title: 'Show Non Translated only',
          type: 'bool',
          field: 'content2',
        },
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getDomains().then((data) => {
      while (this.filters[2].data.length > 0) {
        this.filters[2].data.pop();
      }
      for (let i = 0; i < data.data.length; i += 1) {
        this.filters[2].data.push(data.data[i]);
      }
    });
  },
  computed: {
    headers() {
      const retVal = [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Key',
          value: 'key',
        },
        {
          text: 'Domain',
          value: 'domain',
        },
      ];

      this.$store.state.languages.forEach((lang) => {
        retVal.push({
          text: lang.charAt(0).toUpperCase() + lang.slice(1),
          value: lang,
        });
      });

      retVal.push({
        text: '',
        value: 'actions',
        sortable: false,
      });

      return retVal;
    },
  },
  methods: {
    newItem(action) {
      action({
        key: '',
        de: '',
        fr: '',
        en: '',
      });
    },
    editItem(item, action) {
      BackendService.updateTranslation(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this translation?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteTranslation(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
  },
};
</script>
