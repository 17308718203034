<template>
  <div>
    <ZebraboxDataTable
      apipath="requests"
      :headers="[
        {
          text: 'Created At',
          value: 'createdAt',
          type: 'datetime',
        },
        {
          text: 'Offer Type',
          value: 'offerType',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Duration',
          value: 'duration',
        },
        {
          text: 'Size',
          value: 'size',
        },
        {
          text: 'First Name',
          value: 'firstName',
        },
        {
          text: 'Last Name',
          value: 'lastName',
        },
      ]"
      :filters="filters"
      @exportAction="excelExport"
    />
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';

export default {
  name: 'Requests',
  components: {
    ZebraboxDataTable,
  },
  data() {
    return {
      filters: [
        {
          title: 'Created At',
          type: 'daterange',
          field: 'createdAt',
        },
        {
          title: 'Location',
          type: 'relation',
          field: 'location_id',
          data: [],
        },
      ],
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      while (this.filters[1].data.length > 0) {
        this.filters[1].data.pop();
      }
      for (let i = 0; i < data.data.length; i += 1) {
        this.filters[1].data.push(data.data[i]);
      }
    });
  },
  methods: {
    excelExport(filters) {
      BackendService.export('requests', filters).then((data) => {
        const fileURL = window.URL.createObjectURL(new Blob([data.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;

        const date = new Date();
        fileLink.setAttribute('download', `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_Web_Leads.xlsx`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
