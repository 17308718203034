import axios from 'axios';
import store from '../store/index';

const apiClient = axios.create({
  baseURL: '/adminapi',
  withCredentials: false,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getState() {
    return apiClient.get('/state');
  },
  login(data) {
    return apiClient.post('/login', data);
  },
  logout() {
    return apiClient.get('/logout');
  },
  getDataTableData(path, options, filters) {
    return apiClient.post(`/datatable/${path}`, { options, filters }).catch((error) => {
      if (error.response.status === 403) {
        store.commit('logout');
      }
    });
  },
  export(path, filters) {
    return apiClient.post(`/export/${path}`, { filters }, { responseType: 'blob' }).catch((error) => {
      if (error.response.status === 403) {
        store.commit('logout');
      }
    });
  },
  getLocations() {
    return apiClient.get('/locationsdata');
  },
  getStorageSizes() {
    return apiClient.get('/storagesizedata');
  },
  geContracttypes() {
    return apiClient.get('/contracttypedata');
  },
  getMedia() {
    return apiClient.get('/media');
  },
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    return apiClient.post('/uploadmedia', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  },
  updateCategory(item) {
    return apiClient.post('/savecategory', item);
  },
  deleteCategory(id) {
    return apiClient.post('/deletecategory', { id });
  },
  updateLocation(item) {
    return apiClient.post('/savelocation', item);
  },
  deleteLocation(id) {
    return apiClient.post('/deletelocation', { id });
  },
  updateTranslation(item) {
    return apiClient.post('/savetranslation', item);
  },
  deleteTranslation(id) {
    return apiClient.post('/deletetranslation', { id });
  },
  updateVoucher(item) {
    return apiClient.post('/savevoucher', item);
  },
  updateInsurance(item) {
    return apiClient.post('/saveinsurance', item);
  },
  updateCountryInsurance(item) {
    return apiClient.post('/savecountryinsurance', item);
  },
  updateMatrix(item) {
    return apiClient.post('/savematrix', item);
  },
  deleteMatrix(id) {
    return apiClient.post('/deletematrix', { id });
  },
  deleteCountryInsurance(id) {
    return apiClient.post('/deletecountryinsurance', { id });
  },
  updateDuration(item) {
    return apiClient.post('/saveduration', item);
  },
  deleteDuration(id) {
    return apiClient.post('/deleteduration', { id });
  },
  updateSize(item) {
    return apiClient.post('/savesize', item);
  },
  deleteSize(id) {
    return apiClient.post('/deletesize', { id });
  },
  updateReferrerType(item) {
    return apiClient.post('/savereferrertype', item);
  },
  deleteReferrerType(id) {
    return apiClient.post('/deletereferrertype', { id });
  },
  getStore365referrer() {
    return apiClient.get('/store365referrerdata');
  },
  getStoragereason365() {
    return apiClient.get('/store365reasondata');
  },
  updateStorageReason(item) {
    return apiClient.post('/savestoragereason', item);
  },
  deleteStorageReason(id) {
    return apiClient.post('/deletestoragereason', { id });
  },
  updateCountry(item) {
    return apiClient.post('/savecountry', item);
  },
  deleteCountry(id) {
    return apiClient.post('/deletecountry', { id });
  },
  updateStoragePurpose(item) {
    return apiClient.post('/savestoragepurpose', item);
  },
  deleteStoragePurpose(id) {
    return apiClient.post('/deletestoragepurpose', { id });
  },
  getStoragepurpose365() {
    return apiClient.get('/store365purposedata');
  },
  heartbeat() {
    return apiClient.get('/heartbeat');
  },
  getDomains() {
    return apiClient.get('/domainsdata');
  },
  toggleLocation(id) {
    return apiClient.post('/togglelocation', { id });
  },
  getCountryData() {
    return apiClient.get('/countrydata');
  },
  saveCountrySettingsCountry(country) {
    return apiClient.post('/savecountrysettingscountry', { country });
  },
  saveCountrySettingsVat(vat) {
    return apiClient.post('/savecountrysettingsvat', { vat });
  },
  saveCountrySettingsCurrency(currency) {
    return apiClient.post('/savecountrysettingscurrency', { currency });
  },
  saveCountrySettingsMinAge(minage) {
    return apiClient.post('/savecountrysettingsminage', { minage });
  },
};
