var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"vouchers","headers":[
      {
        text: 'Code',
        value: 'description',
      },
      {
        text: 'Title',
        value: `title.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Valid From',
        value: 'validfromFormatted',
      },
      {
        text: 'Valid To',
        value: 'validtoFormatted',
      },
      {
        text: 'Locations',
        value: 'locations',
        sortable: false,
        type: 'mapnames',
      },
      {
        text: 'Import Date',
        value: 'importdate',
        type: 'date',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"editAction":_vm.editItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('v-text-field',{attrs:{"label":"Code","disabled":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('lang-tabs',{attrs:{"model":item,"config":{
            title: {
              label: 'Title',
            },
          }}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._v(" "+_vm._s(item.validfrom)+" "),_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Valid From","prepend-icon":"fal fa-calendar","readonly":"","clearable":""},on:{"blur":function($event){item.validfrom =
                      _vm.parseDate(item.validfromFormatted)},"click:clear":function($event){return _vm.clearValidFromDate(item)}},model:{value:(item.validfromFormatted),callback:function ($$v) {_vm.$set(item, "validfromFormatted", $$v)},expression:"item.validfromFormatted"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.validfrommenu),callback:function ($$v) {_vm.$set(item, "validfrommenu", $$v)},expression:"item.validfrommenu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.onValidFromDatePickerInput(item)}},model:{value:(item.validfrom),callback:function ($$v) {_vm.$set(item, "validfrom", $$v)},expression:"item.validfrom"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Valid To","prepend-icon":"fal fa-calendar","readonly":"","clearable":""},on:{"blur":function($event){item.validto =
                      _vm.parseDate(item.validtoFormatted)},"click:clear":function($event){return _vm.clearValidToDate(item)}},model:{value:(item.validtoFormatted),callback:function ($$v) {_vm.$set(item, "validtoFormatted", $$v)},expression:"item.validtoFormatted"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.validtomenu),callback:function ($$v) {_vm.$set(item, "validtomenu", $$v)},expression:"item.validtomenu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.onValidToDatePickerInput(item)}},model:{value:(item.validto),callback:function ($$v) {_vm.$set(item, "validto", $$v)},expression:"item.validto"}})],1),_c('v-combobox',{attrs:{"label":"Locations","items":_vm.locations,"chips":"","clearable":"","multiple":"","item-value":"id","item-text":"name","solo":""},model:{value:(item.locations),callback:function ($$v) {_vm.$set(item, "locations", $$v)},expression:"item.locations"}}),_c('v-text-field',{attrs:{"label":"Import date","prepend-icon":"fal fa-calendar","disabled":""},model:{value:(item.importdateFormatted),callback:function ($$v) {_vm.$set(item, "importdateFormatted", $$v)},expression:"item.importdateFormatted"}})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }