var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"countries","headers":[
      {
        text: 'Country Code',
        value: 'countrycode',
      },
      {
        text: 'Name',
        value: `name.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Icon Code',
        value: 'iconcode',
      },
      {
        text: 'Prefix',
        value: 'prefix',
      },
      {
        text: 'Position',
        value: 'position',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"newAction":_vm.newItem,"editAction":_vm.editItem,"deleteAction":_vm.deleteItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('v-text-field',{attrs:{"label":"Country Code"},model:{value:(item.countrycode),callback:function ($$v) {_vm.$set(item, "countrycode", $$v)},expression:"item.countrycode"}}),_c('lang-tabs',{attrs:{"model":item,"config":{
            name: {
              label: 'Name',
            },
          }}}),_c('v-text-field',{attrs:{"label":"Icon Code"},model:{value:(item.iconcode),callback:function ($$v) {_vm.$set(item, "iconcode", $$v)},expression:"item.iconcode"}}),_c('v-text-field',{attrs:{"label":"Prefix"},model:{value:(item.prefix),callback:function ($$v) {_vm.$set(item, "prefix", $$v)},expression:"item.prefix"}}),_c('v-text-field',{attrs:{"label":"Position"},model:{value:(item.position),callback:function ($$v) {_vm.$set(item, "position", $$v)},expression:"item.position"}})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }