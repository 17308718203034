<template>
  <div>
    <ZebraboxDataTable
      apipath="insurance"
      :headers="[
        {
          text: 'Code',
          value: 'description',
        },
        {
          text: 'Title',
          value: `title.${$store.state.languages[0]}`,
        },
        {
          text: 'Size From (m³)',
          value: 'sizefrom',
        },
        {
          text: 'Size To (m³)',
          value: 'sizeto',
        },
        {
          text: 'Premiums',
          value: 'premiums',
          sortable: false,
          type: 'insurancepremiums',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @editAction="editItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-text-field label="Code" v-model="item.description" disabled />
        <lang-tabs :model="item" :config="{
              title: {
                label: 'Title',
              },
            }" />
        <v-text-field label="Size From (m³)" v-model="item.sizefrom" />
        <v-text-field label="Size To (m³)" v-model="item.sizeto" />

        <b>Premiums</b>
            <v-data-table
              :headers="[
                {
                  'text':'Max Insured',
                  'value':'maxinsuredvalue',
                },
                {
                  'text':'premium',
                  'value':'premium',
                },
                {
                  'text':'Gross Premium Business',
                  'value':'grossPremiumBusiness',
                },
                {
                  'text':'Gross Premium Private',
                  'value':'grossPremiumPrivate',
                },
              ]"
              :items="item.premiums"
              :options="{
                itemsPerPage: -1
              }"
            >
              <template v-slot:item.size="{ item }">
                <v-select
                  v-model="item.size"
                  :items="storagesizes"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </template>
              <template v-slot:item.volume="{ item }">
                <v-text-field v-model="item.volume" />
              </template>
              <template v-slot:item.surface="{ item }">
                <v-text-field v-model="item.surface" />
              </template>
              <template v-slot:item.actions="slotProps">
                <v-icon
                  small
                  @click="deleteStoragesizeItem(slotProps.item, item)"
                >
                  far fa-trash
                </v-icon>
              </template>
            </v-data-table>
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Insurance',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      locations: [],
      storagesizes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      this.locations = data.data;
    });
    BackendService.getStorageSizes().then((data) => {
      this.storagesizes = data.data;
    });
  },
  methods: {
    newItem(action) {
      action({
        default: 0,
        firm: 0,
        name: {
          de: '',
          fr: '',
          en: '',
        },
        sizes: [],
      });
    },
    editItem(item, action) {
      BackendService.updateInsurance(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this location?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteLocation(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteStoragesizeItem(subitem, item) {
      this.deleteStoragesizeItemSubItem = subitem;
      this.deleteStoragesizeItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this storage size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteStoragesizeItemConfirm;
    },
    deleteStoragesizeItemConfirm() {
      console.log(this.deleteStoragesizeItemItem);
      this.deleteStoragesizeItemItem.sizes.splice(
        this.deleteStoragesizeItemItem.sizes.indexOf(this.deleteStoragesizeItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
