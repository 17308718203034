<template>
  <div>
    <ZebraboxDataTable
      apipath="categories"
      :headers="[
        {
          text: 'Name',
          value: `name.${$store.state.languages[0]}`,
        },
        {
          text: 'Firm',
          value: 'firm',
          type: 'yesno',
        },
        {
          text: 'Default',
          value: 'default',
          type: 'yesno',
        },
        {
          text: 'Category Sizes',
          value: 'sizes',
          type: 'categorysizes',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:editForm="{ item }">
        <v-tabs v-model="editTab">
          <v-tab key="0">
            General
          </v-tab>
          <v-tab key="1">
            Category Sizes
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="editTab">
          <v-tab-item key="0">
            <lang-tabs :model="item" :config="{
              name: {
                label: 'Name',
              },
            }" />
            <v-checkbox label="Firm" v-model="item.firm" :true-value="1" :false-value="0" />
            <v-checkbox label="Default" v-model="item.default" :true-value="1" :false-value="0" />
          </v-tab-item>
          <v-tab-item key="1">
            <v-data-table
              :headers="[
                {
                  'text':'Storage Size',
                  'value':'size',
                },
                {
                  'text':'Size Image',
                  'value':'image',
                },
                {
                  'text':'Translations Descriptive Image',
                  'value':'descriptiveimage',
                },
                {
                  'text':'',
                  'value':'actions',
                  'sortable':false,
                },
              ]"
              :items="item.sizes"
              :options="{
                itemsPerPage: -1
              }"
            >
              <template v-slot:item.size="{ item }">
                <v-select
                  v-model="item.size"
                  :items="storagesizes"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </template>
              <template v-slot:item.image="{ item }">
                <ImageSelector :image="item.image" ></ImageSelector>
              </template>
              <template v-slot:item.descriptiveimage="{ item : subitem }">
                <lang-tabs :model="subitem" :config="{
                  descriptiveImage: {
                    label: 'Descriptive image',
                    imageselector: true,
                  },
                  mobileDescriptiveImage: {
                    label: 'Mobile descriptive image',
                    imageselector: true,
                  },
                  description: {
                    label: 'Description',
                  },
            }" />
              </template>
              <template v-slot:item.actions="slotProps">
                <v-icon
                  small
                  @click="deleteCategoryItem(slotProps.item, item)"
                >
                  far fa-trash
                </v-icon>
              </template>
              <template v-slot:footer>
                <v-toolbar
                  flat
                >
                  <v-spacer />
                  <v-btn color="primary" class="mb-2" @click="item.sizes.push({
                    size: undefined,
                    sizeName: '',
                    image: {
                      name: undefined,
                      id: undefined,
                    },
                    descriptiveImage: {
                      de: {
                        name: undefined,
                        id: undefined,
                      },
                      fr: {
                        name: undefined,
                        id: undefined,
                      },
                      en: {
                        name: undefined,
                        id: undefined,
                      },
                    },
                    mobileDescriptiveImage: {
                      de: {
                        name: undefined,
                        id: undefined,
                      },
                      fr: {
                        name: undefined,
                        id: undefined,
                      },
                      en: {
                        name: undefined,
                        id: undefined,
                      },
                    },
                    description: {
                      de: undefined,
                      fr: undefined,
                      en: undefined,
                    }
                  })">
                    New Entry
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';
import ImageSelector from '../components/ImageSelector.vue';

export default {
  name: 'Categories',
  components: {
    ImageSelector,
    LangTabs,
    ZebraboxDataTable,
  },
  data() {
    return {
      confirmDialog: false,
      confirmDialogText: '',
      deleteCategoryItemItem: null,
      deleteCategoryItemSubItem: null,
      confirmDialogAction: null,
      editTab: 0,
      deleteItemObject: null,
      deleteItemAction: null,
      storagesizes: [],
      filters: [
      ],
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      while (this.filters[1].data.length > 0) {
        this.filters[1].data.pop();
      }
      for (let i = 0; i < data.data.length; i += 1) {
        this.filters[1].data.push(data.data[i]);
      }
    });
    BackendService.getStorageSizes().then((data) => {
      this.storagesizes = data.data;
    });
    BackendService.getMedia().then((data) => {
      this.$store.commit('media', data.data);
    });
  },
  methods: {
    newItem(action) {
      action({
        default: 0,
        firm: 0,
        name: {
          de: '',
          fr: '',
          en: '',
        },
        sizes: [],
      });
    },
    editItem(item, action) {
      BackendService.updateCategory(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this category?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteCategory(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteCategoryItem(subitem, item) {
      this.deleteCategoryItemSubItem = subitem;
      this.deleteCategoryItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this category size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteCategoryItemConfirm;
    },
    deleteCategoryItemConfirm() {
      this.deleteCategoryItemItem.sizes.splice(
        this.deleteCategoryItemItem.sizes.indexOf(this.deleteCategoryItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
