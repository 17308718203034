<template>
  <div>
    <ZebraboxDataTable
      apipath="referrertypes"
      :headers="[
        {
          text: 'Name',
          value: `name.${$store.state.languages[0]}`,
        },
        {
          text: 'Store365',
          value: 'store365Name',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <lang-tabs :model="item" :config="{
              name: {
                label: 'Name',
              },
            }" />
        <v-select
          label="Request type"
          v-model="item.store365"
          :items="store365"
          item-text="name"
          item-value="id"
        ></v-select>
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'ReferrerTypes',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      store365: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getStore365referrer().then((data) => {
      this.store365 = data.data;
    });
  },
  methods: {
    newItem(action) {
      action({
        store365: null,
        name: {
          de: '',
          fr: '',
          en: '',
        },
      });
    },
    editItem(item, action) {
      BackendService.updateReferrerType(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this referrer type?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteReferrerType(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteStoragesizeItem(subitem, item) {
      this.deleteStoragesizeItemSubItem = subitem;
      this.deleteStoragesizeItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this storage size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteStoragesizeItemConfirm;
    },
    deleteStoragesizeItemConfirm() {
      console.log(this.deleteStoragesizeItemItem);
      this.deleteStoragesizeItemItem.sizes.splice(
        this.deleteStoragesizeItemItem.sizes.indexOf(this.deleteStoragesizeItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
