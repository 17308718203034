var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"categories","headers":[
      {
        text: 'Name',
        value: `name.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Firm',
        value: 'firm',
        type: 'yesno',
      },
      {
        text: 'Default',
        value: 'default',
        type: 'yesno',
      },
      {
        text: 'Category Sizes',
        value: 'sizes',
        type: 'categorysizes',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"newAction":_vm.newItem,"editAction":_vm.editItem,"deleteAction":_vm.deleteItem},scopedSlots:_vm._u([{key:"editForm",fn:function({ item }){return [_c('v-tabs',{model:{value:(_vm.editTab),callback:function ($$v) {_vm.editTab=$$v},expression:"editTab"}},[_c('v-tab',{key:"0"},[_vm._v(" General ")]),_c('v-tab',{key:"1"},[_vm._v(" Category Sizes ")])],1),_c('v-tabs-items',{model:{value:(_vm.editTab),callback:function ($$v) {_vm.editTab=$$v},expression:"editTab"}},[_c('v-tab-item',{key:"0"},[_c('lang-tabs',{attrs:{"model":item,"config":{
            name: {
              label: 'Name',
            },
          }}}),_c('v-checkbox',{attrs:{"label":"Firm","true-value":1,"false-value":0},model:{value:(item.firm),callback:function ($$v) {_vm.$set(item, "firm", $$v)},expression:"item.firm"}}),_c('v-checkbox',{attrs:{"label":"Default","true-value":1,"false-value":0},model:{value:(item.default),callback:function ($$v) {_vm.$set(item, "default", $$v)},expression:"item.default"}})],1),_c('v-tab-item',{key:"1"},[_c('v-data-table',{attrs:{"headers":[
              {
                'text':'Storage Size',
                'value':'size',
              },
              {
                'text':'Size Image',
                'value':'image',
              },
              {
                'text':'Translations Descriptive Image',
                'value':'descriptiveimage',
              },
              {
                'text':'',
                'value':'actions',
                'sortable':false,
              },
            ],"items":item.sizes,"options":{
              itemsPerPage: -1
            }},scopedSlots:_vm._u([{key:"item.size",fn:function({ item }){return [_c('v-select',{attrs:{"items":_vm.storagesizes,"item-text":"name","item-value":"id"},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}})]}},{key:"item.image",fn:function({ item }){return [_c('ImageSelector',{attrs:{"image":item.image}})]}},{key:"item.descriptiveimage",fn:function({ item : subitem }){return [_c('lang-tabs',{attrs:{"model":subitem,"config":{
                descriptiveImage: {
                  label: 'Descriptive image',
                  imageselector: true,
                },
                mobileDescriptiveImage: {
                  label: 'Mobile descriptive image',
                  imageselector: true,
                },
                description: {
                  label: 'Description',
                },
          }}})]}},{key:"item.actions",fn:function(slotProps){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteCategoryItem(slotProps.item, item)}}},[_vm._v(" far fa-trash ")])]}},{key:"footer",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return item.sizes.push({
                  size: undefined,
                  sizeName: '',
                  image: {
                    name: undefined,
                    id: undefined,
                  },
                  descriptiveImage: {
                    de: {
                      name: undefined,
                      id: undefined,
                    },
                    fr: {
                      name: undefined,
                      id: undefined,
                    },
                    en: {
                      name: undefined,
                      id: undefined,
                    },
                  },
                  mobileDescriptiveImage: {
                    de: {
                      name: undefined,
                      id: undefined,
                    },
                    fr: {
                      name: undefined,
                      id: undefined,
                    },
                    en: {
                      name: undefined,
                      id: undefined,
                    },
                  },
                  description: {
                    de: undefined,
                    fr: undefined,
                    en: undefined,
                  }
                })}}},[_vm._v(" New Entry ")])],1)]},proxy:true}],null,true)})],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }