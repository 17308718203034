<template>
    <div>
      <v-tabs v-model="tab">
        <v-tab v-for="(lang, i) in $store.state.languages" v-bind:key="i">
          {{lang.charAt(0).toUpperCase() + lang.slice(1) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="pt-2">
        <v-tab-item  v-for="(lang, i) in $store.state.languages" v-bind:key="i">
          <div v-for="(fieldConfig, fieldKey) in config" v-bind:key="fieldKey">
            <v-textarea
              auto-grow
              :label="fieldConfig.label"
              v-model="model[fieldKey][lang]"
              rows="1"
              v-if="fieldConfig.textArea"
            />
            <ImageSelector
              :image="model[fieldKey][lang]"
              :label="fieldConfig.label"
              v-else-if="fieldConfig.imageselector"
           />
            <v-text-field
              :label="fieldConfig.label"
              v-model="model[fieldKey][lang]"
              v-else
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
</template>

<script>
import ImageSelector from './ImageSelector.vue';

export default {
  name: 'LangTabs',
  components: {
    ImageSelector,
  },
  data() {
    return {
      tab: 0,
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>

</style>
