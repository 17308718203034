<template>
  <div>
    <ZebraboxDataTable
      apipath="durations"
      :headers="[
        {
          text: 'Time Period',
          value: `timePeriod.${$store.state.languages[0]}`,
        },
        {
          text: 'Contract Type',
          value: 'contracttypeName',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-text-field label="Length" v-model="item.length" />
        <v-text-field label="External Id" v-model="item.externalId" />

        <v-select
          label="Contract type"
          v-model="item.contracttype"
          :items="contracttypes"
          item-text="name"
          item-value="id"
        ></v-select>

        <v-checkbox label="Has Info" v-model="item.has_info" :true-value="1" :false-value="0"
        ></v-checkbox>

        <lang-tabs :model="item" :config="{
              timePeriod: {
                label: 'Time period',
              },
            }" />
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Durations',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      contracttypes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.geContracttypes().then((data) => {
      this.contracttypes = data.data;
    });
  },
  methods: {
    newItem(action) {
      action({
        length: '',
        externalId: '',
        contracttype: null,
        has_info: 0,
        timePeriod: {
          de: '',
          fr: '',
          en: '',
        },
        sizes: [],
      });
    },
    editItem(item, action) {
      BackendService.updateDuration(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this duration?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteDuration(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
  },
};
</script>
