<template>
  <v-container fill-height>
    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <div class="layout-content ma-auto login-wrapper">
        <v-card class="text-center pa-1">
          <v-card-title class="justify-center display-1 mb-2">Zebrabox</v-card-title>
          <v-card-subtitle>Please sign in</v-card-subtitle>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                outlined
                label="Username"
                :rules="[rules.required]"
                v-model="username"
                autocomplete="off"
              ></v-text-field>
              <v-text-field
                outlined
                label="Password"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'far fa-eye' : 'far fa-eye-slash'"
                @click:append="show = !show"
                :rules="[rules.required, rules.loginfailed]"
                v-model="password"
              ></v-text-field>
              <v-btn
                block
                class="primary black--text"
                @click="login"
                :loading="loading">Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>

</template>

<script>
import BackendService from '../service/BackendService';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      show: false,
      loading: false,
      showLoginError: false,
      rules: {
        required: (value) => !!value || 'Required.',
        loginfailed: () => {
          if (!this.showLoginError) {
            return true;
          }

          this.showLoginError = false;
          return 'Username or Password is incorrect';
        },
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.showLoginError = false;
      if (!this.$refs.form.validate()) {
        this.loading = false;
      } else {
        BackendService.login({
          login: this.username,
          password: this.password,
        }).then((data) => {
          this.loading = false;
          this.showLoginError = true;
          window.setTimeout(() => {
            this.$refs.form.validate();
          });
          this.$store.commit('upadteState', data.data.data);
        });
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  width: 480px;
  max-width: 100%;
}
</style>
