import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    gotstate: false,
    loggedin: false,
    languages: [],
    media: [],
  },
  mutations: {
    upadteState(state, payload) {
      state.gotstate = true;
      state.loggedin = payload.loggedin;
      state.languages = payload.languages;
    },
    updateLoginState(state, payload) {
      state.loggedin = payload;
    },
    media(state, payload) {
      state.media = payload;
    },
    addMedia(state, payload) {
      state.media.push(payload);
    },
    logout(state) {
      state.loggedin = false;
    },
  },
  actions: {
  },
  modules: {
  },
});
