<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field readonly outlined dense :label="label" v-model="filename"  />
      </v-col>
      <v-col>
        <v-btn outlined x-small color="#00acd6" @click.prevent="openList">
          <v-icon x-small class="mr-2">fa fa-list</v-icon> Liste
        </v-btn>
        <v-btn outlined x-small color="#008d4c" @click.prevent="addNew">
          <v-icon x-small class="mr-2">fas fa-plus-circle</v-icon> Neu
        </v-btn>
        <v-btn outlined x-small color="#e08e0b" @click.prevent="openEdit">
          <v-icon x-small class="mr-2">fa fa-edit</v-icon> Bearbeiten
        </v-btn>
        <v-btn outlined x-small color="#d73925" @click.prevent="clear">
          <v-icon x-small class="mr-2">fas fa-minus-circle</v-icon> Löschen
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="imageselectDialog"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-sheet elevation="1">
              <v-toolbar dense elevation="0">
                <v-spacer></v-spacer>

                <v-menu offset-y
                        :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      :content="filter.length"
                      :value="filter.length"
                      overlap
                    >
                      <v-btn elevation="1"
                             v-bind="attrs"
                             v-on="on"
                      >
                        <v-icon small>fal fa-filter</v-icon>&nbsp;
                        Filter
                      </v-btn>
                    </v-badge>
                  </template>

                  <v-list
                    flat
                    subheader
                  >
                    <v-list-item-group
                      v-model="filter"
                      multiple
                      active-class=""
                    >
                      <v-list-item v-for="(item, id) in tablefilters" v-bind:key="id">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>

                </v-menu>
              </v-toolbar>
              <v-container v-if="filter.length > 0">
                <v-row>
                  <v-col v-for="(item, id) in filter" v-bind:key="id" cols="4">
                    {{ tablefilters[item].title }}
                    <v-text-field v-model="tablefilters[item].value" clearable />
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="4" v-for="n in 12" v-bind:key="n">
                <div class="imagecard"
                     v-if="filteredData.length -1 >= (page - 1) * 12 + n - 1"
                     @click="choose(filteredData[(page - 1) * 12 + n - 1].id)">
                  <div class="image"
                       :style="{
                          backgroundImage:
                          `url(/media/adminthumb/${filteredData[(page - 1) * 12 + n - 1].id})`
                  }">
                  </div>
                  {{ filteredData[(page - 1) * 12 + n - 1].name }}
                </div>
              </v-col>
            </v-row>
             <v-pagination v-if="filteredData.length / 12  > 1"
              v-model="page"
              :length="Math.ceil(filteredData.length / 12)"
              prev-icon="fas fa-caret-left"
              next-icon="fas fa-caret-right"
              total-visible="9"
            ></v-pagination>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeImageselect"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imageDialog"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <b>Upload:</b>
                <v-file-input
                  label="Image"
                  filled
                  prepend-icon="fal fa-camera"
                  v-model="file"
                  accept="image/png, image/jpeg, image/gif"
                  :loading="true"
                  @change="updatePreviewImage"
                ></v-file-input>
              </v-col>
              <v-col cols="6">
                <b>Preview:</b><br />
                <img :src="`/media/adminthumb/${editImageOrig.id}`"
                     v-if="file === null && editImageOrig.id !== undefined"
                     class="previewImage" />
                <img :src="imagePreviewUrl" v-else-if="imagePreviewUrl !== null"
                     class="previewImage" />
                <br />
                <br />
                <b>Name:</b><br />
                <span v-if="file === null">{{ editImageOrig.name }}</span>
                <span v-else>{{ editImage.name }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeImageedit"
          >
            close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveImageedit"
            :loading="uploading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import BackendService from '../service/BackendService';

export default {
  name: 'ImageSelector',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: 1,
      uploading: false,
      filter: [],
      filteredData: JSON.parse(JSON.stringify(this.$store.state.media)),
      tablefilters: [
        {
          title: 'Name',
          field: 'name',
        },
      ],
      imageselectDialog: false,
      imageDialog: false,
      editImage: {},
      editImageOrig: {},
      file: null,
      imagePreviewUrl: null,
    };
  },
  watch: {
    filter: {
      handler() {
        this.updateFilteredData();
      },
      deep: true,
    },
    tablefilters: {
      handler() {
        this.updateFilteredData();
      },
      deep: true,
    },
  },
  computed: {
    filename() {
      if (this.image.id !== undefined) {
        const res = this.$store.state.media.find((item) => item.id === this.image.id);
        if (res === undefined) {
          return '';
        }
        return res.name;
      }
      return '';
    },
  },
  methods: {
    updatePreviewImage() {
      if (this.file === null) {
        this.imagePreviewUrl = null;
        this.editImage.name = '';
        this.editImage.id = null;
      } else {
        this.imagePreviewUrl = URL.createObjectURL(this.file);
        this.editImage.name = this.file.name;
        this.editImage.id = null;
      }
    },
    updateFilteredData() {
      while (this.filteredData.length > 0) {
        this.filteredData.pop();
      }
      if (this.filter.indexOf(0) === -1 || this.tablefilters[0].value === undefined
        || this.tablefilters[0].value.length === 0) {
        this.filteredData = JSON.parse(JSON.stringify(this.$store.state.media));
      } else {
        this.filteredData = this.$store.state.media.filter(
          (item) => item.name.toLowerCase().indexOf(this.tablefilters[0].value.toLowerCase())
            !== -1,
        );
      }
    },
    openList() {
      this.imageselectDialog = true;
    },
    addNew() {
      this.editImageOrig = {};
      this.editImage = {};
      this.file = null;
      this.loading = false;
      this.imageDialog = true;
      this.imagePreviewUrl = null;
    },
    openEdit() {
      this.editImageOrig = { id: this.image.id, name: this.image.name };
      this.editImage = { id: this.image.id, name: this.image.name };
      this.loading = false;
      this.imageDialog = true;
      this.imagePreviewUrl = null;
    },
    clear() {
      this.image.id = undefined;
    },
    closeImageselect() {
      this.imageselectDialog = false;
    },
    saveImageedit() {
      if (this.file !== null) {
        this.loading = true;
        BackendService.uploadFile(this.file).then((data) => {
          this.loading = false;
          this.$store.commit('addMedia', data.data);
          this.image.id = data.data.id;
          this.imageDialog = false;
        });
      } else {
        this.imageDialog = false;
      }
    },
    closeImageedit() {
      this.imageDialog = false;
    },
    choose(id) {
      this.image.id = id;
      this.imageselectDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
 .imagecard {
   max-width: 390px;
   cursor: pointer;

   .image {
     height: 100px;
   }
 }

.previewImage {
  max-width: 200px;
  max-height: 200px;
}
</style>
