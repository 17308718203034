var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"matrix","headers":[
      {
        text: 'Location',
        value: 'locationName',
      },
      {
        text: 'Floor',
        value: 'floor',
      },
      {
        text: 'Matrix Unit Ranges',
        value: 'ranges',
        type: 'matrixranges',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"newAction":_vm.newItem,"editAction":_vm.editItem,"deleteAction":_vm.deleteItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('v-select',{attrs:{"label":"Location","items":_vm.locations,"item-text":"name","item-value":"id"},model:{value:(item.location),callback:function ($$v) {_vm.$set(item, "location", $$v)},expression:"item.location"}}),_c('v-text-field',{attrs:{"label":"Floor"},model:{value:(item.floor),callback:function ($$v) {_vm.$set(item, "floor", $$v)},expression:"item.floor"}}),_c('b',[_vm._v("Matrix Unit Ranges")]),_c('v-data-table',{attrs:{"headers":[
              {
                'text':'From Unit',
                'value':'fromUnit',
              },
              {
                'text':'To Unit',
                'value':'toUnit',
              },
              {
                'text':'',
                'value':'actions',
                'sortable':false,
              },
            ],"items":item.ranges,"options":{
              itemsPerPage: -1
            }},scopedSlots:_vm._u([{key:"item.fromUnit",fn:function({ item }){return [_c('v-text-field',{model:{value:(item.fromUnit),callback:function ($$v) {_vm.$set(item, "fromUnit", $$v)},expression:"item.fromUnit"}})]}},{key:"item.toUnit",fn:function({ item }){return [_c('v-text-field',{model:{value:(item.toUnit),callback:function ($$v) {_vm.$set(item, "toUnit", $$v)},expression:"item.toUnit"}})]}},{key:"item.actions",fn:function(slotProps){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteUnitRangeItem(slotProps.item, item)}}},[_vm._v(" far fa-trash ")])]}},{key:"footer",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){return item.ranges.push({
                  fromUnit: '',
                  toUnit: '',
                })}}},[_vm._v(" New Entry ")])],1)]},proxy:true}],null,true)}),_c('lang-tabs',{attrs:{"model":item,"config":{
            map: {
              label: 'Map',
            },
            instructions: {
              label: 'Instructions',
            },
          }}})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }