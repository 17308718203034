<template>
  <div>
    <ZebraboxDataTable
      apipath="countryinsurance"
      title="Storage values"
      :headers="[
        {
          text: 'Value',
          value: 'value',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:editForm="{ item }">
        <v-text-field label="Value" v-model="item.value" />
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>Preferred Country</h2>
              <p>for preselecting in dropdowns</p>
              <v-select
                v-model="country"
                :items="countries"
                item-text="name"
                item-value="id"
              ></v-select><br />
              <v-btn
                color="primary"
                @click.prevent="saveCountry"
                :loading="savingCountry">save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>Currency</h2>
              <p v-pre>for replacing in texts &amp; translations (use {{currency}} in the text)</p>
              <v-text-field v-model="currency" /><br />
              <v-btn color="primary" @click="saveCurrency" :loading="savingCurrency">save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>VAT rate</h2>
              <p v-pre>for replacing in texts &amp; translations (use {{vatrate}} in the text)</p>
              <v-text-field v-model="vat" /><br />
              <v-btn color="primary" @click="saveVat" :loading="savingVat">save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-container>
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12">
              <h2>Minimum age</h2>
              <p v-pre>for replacing in texts &amp; translations (use {{minage}} in the text)</p>
              <v-text-field v-model="minage" /><br />
              <v-btn color="primary" @click="saveMinAge" :loading="savingMinAge">save</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';

export default {
  name: 'CountrySettings',
  components: {
    ZebraboxDataTable,
  },
  data() {
    return {
      editTab: 0,
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
      countries: [],
      currency: '',
      savingCountry: true,
      savingCurrency: true,
      savingVat: true,
      savingMinAge: true,
      country: null,
      minage: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getCountryData().then((data) => {
      this.countries = data.data.countries;
      this.savingCountry = false;
      this.savingCurrency = false;
      this.savingVat = false;
      this.savingMinAge = false;
      if (data.data.settings.country) {
        this.country = data.data.settings.country;
      }
      if (data.data.settings.currency) {
        this.currency = data.data.settings.currency;
      }
      if (data.data.settings.vat) {
        this.vat = data.data.settings.vat;
      }
      if (data.data.settings.minage) {
        this.minage = data.data.settings.minage;
      }
    });
  },
  methods: {
    saveCountry() {
      this.savingCountry = true;
      BackendService.saveCountrySettingsCountry(this.country).then(() => {
        this.savingCountry = false;
      });
    },
    saveCurrency() {
      this.savingCurrency = true;
      BackendService.saveCountrySettingsCurrency(this.currency).then(() => {
        this.savingCurrency = false;
      });
    },
    saveVat() {
      this.savingVat = true;
      BackendService.saveCountrySettingsVat(this.vat).then(() => {
        this.savingVat = false;
      });
    },
    saveMinAge() {
      this.savingMinAge = true;
      BackendService.saveCountrySettingsMinAge(this.minage).then(() => {
        this.savingMinAge = false;
      });
    },
    newItem(action) {
      action({
        value: null,
      });
    },
    editItem(item, action) {
      BackendService.updateCountryInsurance(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this storage value?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteCountryInsurance(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteStoragesizeItem(subitem, item) {
      this.deleteStoragesizeItemSubItem = subitem;
      this.deleteStoragesizeItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this storage size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteStoragesizeItemConfirm;
    },
    deleteStoragesizeItemConfirm() {
      // console.log(this.deleteStoragesizeItemItem);
      this.deleteStoragesizeItemItem.sizes.splice(
        this.deleteStoragesizeItemItem.sizes.indexOf(this.deleteStoragesizeItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
