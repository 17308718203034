var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"requests","headers":[
      {
        text: 'Created At',
        value: 'createdAt',
        type: 'datetime',
      },
      {
        text: 'Offer Type',
        value: 'offerType',
      },
      {
        text: 'Location',
        value: 'location',
      },
      {
        text: 'Duration',
        value: 'duration',
      },
      {
        text: 'Size',
        value: 'size',
      },
      {
        text: 'First Name',
        value: 'firstName',
      },
      {
        text: 'Last Name',
        value: 'lastName',
      },
    ],"filters":_vm.filters},on:{"exportAction":_vm.excelExport}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }