<template>
  <div>
    <ZebraboxDataTable
      apipath="matrix"
      :headers="[
        {
          text: 'Location',
          value: 'locationName',
        },
        {
          text: 'Floor',
          value: 'floor',
        },
        {
          text: 'Matrix Unit Ranges',
          value: 'ranges',
          type: 'matrixranges',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-select
          label="Location"
          v-model="item.location"
          :items="locations"
          item-text="name"
          item-value="id"
        ></v-select>

        <v-text-field label="Floor" v-model="item.floor" />

        <b>Matrix Unit Ranges</b>
        <v-data-table
          :headers="[
                {
                  'text':'From Unit',
                  'value':'fromUnit',
                },
                {
                  'text':'To Unit',
                  'value':'toUnit',
                },
                {
                  'text':'',
                  'value':'actions',
                  'sortable':false,
                },
              ]"
          :items="item.ranges"
          :options="{
                itemsPerPage: -1
              }"
        >
          <template v-slot:item.fromUnit="{ item }">
            <v-text-field v-model="item.fromUnit" />
          </template>
          <template v-slot:item.toUnit="{ item }">
            <v-text-field v-model="item.toUnit" />
          </template>
          <template v-slot:item.actions="slotProps">
            <v-icon
              small
              @click="deleteUnitRangeItem(slotProps.item, item)"
            >
              far fa-trash
            </v-icon>
          </template>
          <template v-slot:footer>
            <v-toolbar
              flat
            >
              <v-spacer />
              <v-btn color="primary" class="mb-2" @click="item.ranges.push({
                    fromUnit: '',
                    toUnit: '',
                  })">
                New Entry
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>

        <lang-tabs :model="item" :config="{
              map: {
                label: 'Map',
              },
              instructions: {
                label: 'Instructions',
              },
            }" />
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Matrix',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      locations: [],
      storagesizes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteUnitRangeItemItem: null,
      deleteUnitRangeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      this.locations = data.data;
    });
  },
  methods: {
    newItem(action) {
      action({
        location: null,
        floor: '',
        ranges: [
          {
            fromUnit: '',
            toUnit: '',
          },
        ],
        map: {
          de: '',
          fr: '',
          en: '',
        },
        instructions: {
          de: '',
          fr: '',
          en: '',
        },
      });
    },
    editItem(item, action) {
      BackendService.updateMatrix(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this record?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteMatrix(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
    deleteUnitRangeItem(subitem, item) {
      this.deleteUnitRangeItemSubItem = subitem;
      this.deleteUnitRangeItemItem = item;
      this.confirmDialogText = 'Are you sure you want to delete this unit range?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteUnitRangeItemConfirm;
    },
    deleteUnitRangeItemConfirm() {
      console.log(this.deleteUnitRangeItemItem);
      this.deleteUnitRangeItemItem.ranges.splice(
        this.deleteUnitRangeItemItem.ranges.indexOf(this.deleteUnitRangeItemSubItem),
        1,
      );
      this.confirmDialog = false;
    },
  },
};
</script>
