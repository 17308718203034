var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"durations","headers":[
      {
        text: 'Time Period',
        value: `timePeriod.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Contract Type',
        value: 'contracttypeName',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"newAction":_vm.newItem,"editAction":_vm.editItem,"deleteAction":_vm.deleteItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('v-text-field',{attrs:{"label":"Length"},model:{value:(item.length),callback:function ($$v) {_vm.$set(item, "length", $$v)},expression:"item.length"}}),_c('v-text-field',{attrs:{"label":"External Id"},model:{value:(item.externalId),callback:function ($$v) {_vm.$set(item, "externalId", $$v)},expression:"item.externalId"}}),_c('v-select',{attrs:{"label":"Contract type","items":_vm.contracttypes,"item-text":"name","item-value":"id"},model:{value:(item.contracttype),callback:function ($$v) {_vm.$set(item, "contracttype", $$v)},expression:"item.contracttype"}}),_c('v-checkbox',{attrs:{"label":"Has Info","true-value":1,"false-value":0},model:{value:(item.has_info),callback:function ($$v) {_vm.$set(item, "has_info", $$v)},expression:"item.has_info"}}),_c('lang-tabs',{attrs:{"model":item,"config":{
            timePeriod: {
              label: 'Time period',
            },
          }}})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }