<template>
  <div><Spinner /></div>
</template>

<script>
import Spinner from '../components/Spinner.vue';

export default {
  name: 'Home',

  components: {
    Spinner,
  },
  created() {
    this.$router.push({ name: 'requests' });
  },
};
</script>
