<template>
  <div>
    <ZebraboxDataTable
      apipath="vouchers"
      :headers="[
        {
          text: 'Code',
          value: 'description',
        },
        {
          text: 'Title',
          value: `title.${$store.state.languages[0]}`,
        },
        {
          text: 'Valid From',
          value: 'validfromFormatted',
        },
        {
          text: 'Valid To',
          value: 'validtoFormatted',
        },
        {
          text: 'Locations',
          value: 'locations',
          sortable: false,
          type: 'mapnames',
        },
        {
          text: 'Import Date',
          value: 'importdate',
          type: 'date',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @editAction="editItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-text-field label="Code" disabled v-model="item.description"></v-text-field>
        <lang-tabs :model="item" :config="{
              title: {
                label: 'Title',
              },
            }" />
        <v-menu
          v-model="item.validfrommenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            {{item.validfrom}}
            <v-text-field
              v-model="item.validfromFormatted"
              label="Valid From"
              prepend-icon="fal fa-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @blur="item.validfrom =
                        parseDate(item.validfromFormatted)"
              @click:clear="clearValidFromDate(item)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.validfrom"
            @input="onValidFromDatePickerInput(item)"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="item.validtomenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="item.validtoFormatted"
              label="Valid To"
              prepend-icon="fal fa-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @blur="item.validto =
                        parseDate(item.validtoFormatted)"
              @click:clear="clearValidToDate(item)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.validto"
            @input="onValidToDatePickerInput(item)"
          ></v-date-picker>
        </v-menu>

        <v-combobox
          label="Locations"
          v-model="item.locations"
          :items="locations"
          chips
          clearable
          multiple
          item-value="id"
          item-text="name"
          solo>
        </v-combobox>

        <v-text-field
          v-model="item.importdateFormatted"
          label="Import date"
          prepend-icon="fal fa-calendar"
          disabled
        ></v-text-field>

      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';
import LangTabs from '../components/LangTabs.vue';

export default {
  name: 'Vouchers',
  components: {
    ZebraboxDataTable,
    LangTabs,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      locations: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
    BackendService.getLocations().then((data) => {
      this.locations = data.data;
    });
  },
  methods: {
    newItem(action) {
      action({
        default: 0,
        firm: 0,
        name: {
          de: '',
          fr: '',
          en: '',
        },
        sizes: [],
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    onValidFromDatePickerInput(item) {
      // eslint-disable-next-line no-param-reassign
      item.validfromFormatted = this.formatDate(item.validfrom);
    },
    clearValidFromDate(item) {
      // eslint-disable-next-line no-param-reassign
      item.validfrom = null;
    },
    onValidToDatePickerInput(item) {
      // eslint-disable-next-line no-param-reassign
      item.validtoFormatted = this.formatDate(item.validto);
    },
    clearValidToDate(item) {
      // eslint-disable-next-line no-param-reassign
      item.validto = null;
    },
    editItem(item, action) {
      BackendService.updateVoucher(item).then(() => {
        action();
      });
    },
    /*
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this location?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteLocation(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
     */
  },
};
</script>
