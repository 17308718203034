var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"insurance","headers":[
      {
        text: 'Code',
        value: 'description',
      },
      {
        text: 'Title',
        value: `title.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Size From (m³)',
        value: 'sizefrom',
      },
      {
        text: 'Size To (m³)',
        value: 'sizeto',
      },
      {
        text: 'Premiums',
        value: 'premiums',
        sortable: false,
        type: 'insurancepremiums',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"editAction":_vm.editItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('v-text-field',{attrs:{"label":"Code","disabled":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('lang-tabs',{attrs:{"model":item,"config":{
            title: {
              label: 'Title',
            },
          }}}),_c('v-text-field',{attrs:{"label":"Size From (m³)"},model:{value:(item.sizefrom),callback:function ($$v) {_vm.$set(item, "sizefrom", $$v)},expression:"item.sizefrom"}}),_c('v-text-field',{attrs:{"label":"Size To (m³)"},model:{value:(item.sizeto),callback:function ($$v) {_vm.$set(item, "sizeto", $$v)},expression:"item.sizeto"}}),_c('b',[_vm._v("Premiums")]),_c('v-data-table',{attrs:{"headers":[
              {
                'text':'Max Insured',
                'value':'maxinsuredvalue',
              },
              {
                'text':'premium',
                'value':'premium',
              },
              {
                'text':'Gross Premium Business',
                'value':'grossPremiumBusiness',
              },
              {
                'text':'Gross Premium Private',
                'value':'grossPremiumPrivate',
              },
            ],"items":item.premiums,"options":{
              itemsPerPage: -1
            }},scopedSlots:_vm._u([{key:"item.size",fn:function({ item }){return [_c('v-select',{attrs:{"items":_vm.storagesizes,"item-text":"name","item-value":"id"},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}})]}},{key:"item.volume",fn:function({ item }){return [_c('v-text-field',{model:{value:(item.volume),callback:function ($$v) {_vm.$set(item, "volume", $$v)},expression:"item.volume"}})]}},{key:"item.surface",fn:function({ item }){return [_c('v-text-field',{model:{value:(item.surface),callback:function ($$v) {_vm.$set(item, "surface", $$v)},expression:"item.surface"}})]}},{key:"item.actions",fn:function(slotProps){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteStoragesizeItem(slotProps.item, item)}}},[_vm._v(" far fa-trash ")])]}}],null,true)})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }