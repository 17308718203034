<template>
  <div>
    <ZebraboxDataTable
      apipath="sizes"
      :headers="[
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]"
      :filters="filters"
      @newAction="newItem"
      @editAction="editItem"
      @deleteAction="deleteItem"
    >
      <template v-slot:item.requesttype="{ item }">
        <span>{{ item }}</span>
      </template>
      <template v-slot:editForm="{ item }">
        <v-text-field label="Name" v-model="item.name"  />
      </template>

    </ZebraboxDataTable>
    <v-dialog
      v-model="confirmDialog"
      max-width="300">
      <v-card>
        <v-card-text class="pt-2">
          {{ confirmDialogText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialogAction"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ZebraboxDataTable from '../components/ZebraboxDataTable.vue';
import BackendService from '../service/BackendService';

export default {
  name: 'SizesLocations',
  components: {
    ZebraboxDataTable,
  },
  data() {
    return {
      editTab: 0,
      requesttypes: [
        {
          id: 1,
          name: 'Booking',
        },
        {
          id: 2,
          name: 'Reservation',
        },
        {
          id: 0,
          name: 'Offer',
        },
      ],
      locations: [],
      storagesizes: [],
      filters: [
      ],
      confirmDialog: false,
      confirmDialogText: '',
      deleteStoragesizeItemItem: null,
      deleteStoragesizeItemSubItem: null,
      confirmDialogAction: null,
      deleteItemObject: null,
      deleteItemAction: null,
    };
  },
  mounted() {
  },
  created() {
  },
  methods: {
    newItem(action) {
      action({
        name: '',
      });
    },
    editItem(item, action) {
      BackendService.updateSize(item).then(() => {
        action();
      });
    },
    deleteItem(item, action) {
      this.deleteItemObject = item;
      this.deleteItemAction = action;
      this.confirmDialogText = 'Are you sure you want to delete this size?';
      this.confirmDialog = true;
      this.confirmDialogAction = this.deleteItemConfirm;
    },
    deleteItemConfirm() {
      this.confirmDialog = false;
      BackendService.deleteSize(this.deleteItemObject.id).then(() => {
        this.deleteItemAction();
      });
    },
  },
};
</script>
