var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ZebraboxDataTable',{attrs:{"apipath":"storagereasons","headers":[
      {
        text: 'Name',
        value: `name.${_vm.$store.state.languages[0]}`,
      },
      {
        text: 'Is Firm',
        value: 'is_firm',
        type: 'yesno',
      },
      {
        text: 'Store365',
        value: 'store365Name',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
      },
    ],"filters":_vm.filters},on:{"newAction":_vm.newItem,"editAction":_vm.editItem,"deleteAction":_vm.deleteItem},scopedSlots:_vm._u([{key:"item.requesttype",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}},{key:"editForm",fn:function({ item }){return [_c('lang-tabs',{attrs:{"model":item,"config":{
            name: {
              label: 'Name',
            },
          }}}),_c('v-checkbox',{attrs:{"label":"Is Firm","true-value":1,"false-value":0},model:{value:(item.is_firm),callback:function ($$v) {_vm.$set(item, "is_firm", $$v)},expression:"item.is_firm"}}),_c('v-select',{attrs:{"label":"Store365","items":_vm.storagereason365,"item-text":"name","item-value":"id"},model:{value:(item.store365),callback:function ($$v) {_vm.$set(item, "store365", $$v)},expression:"item.store365"}})]}}])}),_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-2"},[_vm._v(" "+_vm._s(_vm.confirmDialogText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmDialogAction}},[_vm._v(" Yes ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }